<template>
  <div class="misc-wrapper">
    <!-- <b-link class="brand-logo">
      <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">
        Vuexy
      </h2>
    </b-link> -->

    <div class="mt-2 d-flex flex-column justify-content-center align-items-center">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Not authorized! 🔐
        </h2>
        <p class="mb-2">
          Sorry, you do not have access to the page you requested.
        </p>
      </div>
      <div class="p-2">
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          :to="loginRoute()"
        >Back to Home</b-button>
      </div>
      <div class="p-2">
        <b-img
          fluid
          class="forbidden-image"
          :src="imgUrl"
          alt="Not authorized page"
        />
      </div>
    </div>

    <!-- <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Not authorized! 🔐
        </h2>
        <p class="mb-2">
          Sorry, you do not have access to the page you requested.
        </p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          :to="loginRoute()"
        >Back to Home</b-button>
        <b-img
          fluid
          class="forbidden-image"
          :src="imgUrl"
          alt="Not authorized page"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BImg, BButton } from 'bootstrap-vue'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { mapGetters } from 'vuex'

export default {
  components: {
    BImg, BButton,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/access_denied.svg'),
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    loginRoute() {
      return getHomeRouteForLoggedInUser(this.activeUser && this.activeUser.role ? this.activeUser.currentRole.name : null)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
.misc-wrapper {
  min-height: 100%;
  .forbidden-image {
    max-height: 500px;
  }
}
</style>
